import type { $Fetch } from 'nitropack';

export default class ReportService {
  constructor(private fetch: $Fetch) {}

  async saveLog(message: string): Promise<void> {
    await this.fetch('/reports/logs', {
      method: 'POST',
      body: {
        message
      }
    });
  }
}

import type { Directive } from 'vue';

const vFocus: Directive<HTMLElement> = {
  mounted: (el) => {
    let input: HTMLInputElement | HTMLTextAreaElement;

    if (['input', 'textarea'].includes(el.tagName.toLowerCase())) {
      input = el as HTMLInputElement;
    } else {
      input = el.getElementsByTagName('input')[0];

      if (!input) {
        input = el.getElementsByTagName('textarea')[0];
      }
    }

    input.focus();
  }
};

export { vFocus };

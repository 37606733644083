import type { $Fetch } from 'nitropack';

import type { Auth, Guest, GuestLogin, User } from '~/models';

export default class AuthService {
  constructor(private fetch: $Fetch) {}

  async confirmEmail(email: string, code: string): Promise<Auth | null> {
    return await this.fetch<Auth>('/auth/confirmemail', {
      method: 'POST',
      body: { email, code }
    });
  }

  async changePassword(currentPass: string, newPass: string): Promise<void> {
    await this.fetch('/auth/changepass', {
      method: 'POST',
      body: { currentPass, newPass, email: '' }
    });
  }

  async forgotPassword(email: string): Promise<void> {
    await this.fetch('/auth/forgotpassword', {
      method: 'POST',
      body: { email }
    });
  }

  async forgotPasswordGet(uuid: string): Promise<string | null> {
    return await this.fetch<string>(`/auth/forgotpassword/${uuid}`);
  }

  async guestLogin(guest: GuestLogin): Promise<Guest | null> {
    return await this.fetch<Guest>('/auth/guestlogin', {
      method: 'POST',
      body: guest
    });
  }

  async login(username: string, password: string): Promise<Auth | null> {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('grant_type', '');
    params.append('scope', 'admin staff user');
    params.append('client_id', '');
    params.append('client_secret', '');

    return await this.fetch<Auth>('/auth/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
      },
      body: params
    });
  }

  async register(user: Partial<User>): Promise<User | null> {
    return await this.fetch<User>('/auth/register', {
      method: 'POST',
      body: user
    });
  }

  async resendConfirmEmail(email: string): Promise<void> {
    await this.fetch('/auth/confirmemail/resend', {
      method: 'POST',
      body: { email }
    });
  }

  async resetPassword(email: string, newPass: string): Promise<void> {
    await this.fetch('/auth/resetpass', {
      method: 'POST',
      body: { email, newPass }
    });
  }
}

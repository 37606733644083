import type { $Fetch } from 'nitropack';

import type { CategoryDisplayIn, Product } from '~/models';

import type { DefaultFilter } from './filter';

type ProductDisplayIn = 'POS' | 'ONLINE' | 'TABLE';

interface Filter extends Partial<DefaultFilter> {
  name?: boolean;
  displayIn?: ProductDisplayIn;
  categoryUuid?: string;
  categoryDisplayIn?: CategoryDisplayIn;
}

export default class ProductService {
  constructor(private fetch: $Fetch) {}

  async get(uuid: string): Promise<Product | null> {
    return await this.fetch<Product>(`/products/${uuid}`);
  }

  async list(filter?: Filter): Promise<Product[] | null> {
    return await this.fetch<Product[]>('/products/', {
      query: filter
    });
  }
}

import { useNuxtApp } from '#app';

const useReportStore = defineStore('customer-report', () => {
  const { $http } = useNuxtApp();
  
  const saveLog = (message: string): Promise<void> => {
    return $http.report.saveLog(message);
  };

  return {
    saveLog
  };
});

export { useReportStore };

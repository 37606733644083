import type {
  CountryCode,
  GoogleDistance,
  GoogleGeometryLocation,
  GoogleLocation
} from '~/models';

export default class GoogleMapsService {
  constructor() {}

  async getDistance(
    origin: GoogleGeometryLocation,
    destination: GoogleGeometryLocation
  ): Promise<GoogleDistance> {
    return new Promise((resolve, reject) => {
      const directionsService = new google.maps.DirectionsService();
      directionsService.route(
        {
          origin,
          destination,
          travelMode: google.maps.TravelMode.DRIVING
        },
        async (res: google.maps.DirectionsResult | null) => {
          if (!res) {
            return reject();
          }

          const { routes } = res;

          if (!routes[0].legs[0].distance) {
            return reject();
          }

          const { text, value } = routes[0].legs[0].distance;

          resolve({ text, value });
        }
      );
    });
  }

  async getLocationByPostalCode(
    origin: GoogleGeometryLocation,
    bounds: google.maps.LatLngBounds,
    country: CountryCode = 'IE',
    postalCode: string
  ): Promise<GoogleLocation | undefined> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();

      if (!postalCode) {
        return reject(
          new Error(`${country === 'UK' ? 'Postal code' : 'Eircode'} is empty.`)
        );
      }

      geocoder.geocode(
        {
          componentRestrictions: { country, postalCode },
          bounds: bounds
        },
        async (result, status) => {
          if (status === google.maps.GeocoderStatus.OK && result) {
            const { lat, lng } = result[0].geometry.location;

            if (
              lat() >= bounds.getSouthWest().lat() &&
              lat() <= bounds.getNorthEast().lat() &&
              lng() >= bounds.getSouthWest().lng() &&
              lng() <= bounds.getNorthEast().lng()
            ) {
              const {
                geometry: { location }
              } = result[0];

              return resolve({
                ...useGoogle().maps().place(result[0]).toLocation(),
                distance: await this.getDistance(origin, location)
              });
            }

            return reject(new Error('Outside the delivery area'));
          }
          return reject(
            new Error(
              `No results for ${country === 'UK' ? 'postal code' : 'eircode'}`
            )
          );
        }
      );
    });
  }
}

<script lang="ts" setup>
const notify = useNotify();

const authStore = useAuthStore();
const stripeStore = useStripeStore();
const preferenceStore = usePreferenceStore();
const cartStore = useCartStore();

await Promise.all([
  preferenceStore.load(),
  cartStore.load(),
  stripeStore.loadPublishableKey(),
  authStore.refreshProfile()
]);

defineHead(preferenceStore.preference);

const description = preferenceStore.preference.general.seo?.description;
const openGraphImage = preferenceStore.preference.general.seo?.openGraphImage;

useSeoMeta({
  description,
  ogDescription: description,
  ogImage: openGraphImage || undefined,
});

eventBus.on(Events.SERVICE_ERROR, (event) => notify.error(String(event)));
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtPwaManifest />

    <NuxtLayout>
      <NuxtPage />

      <UNotifications />
      <UModals />
    </NuxtLayout>
  </div>
</template>

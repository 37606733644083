import type { $Fetch } from 'nitropack';

import type { DiscountVoucher } from '~/models';

interface Filter {
  startDate?: string;
  endDate?: string;
  productUuid?: string;
  voucherCode?: string;
  onlyActive?: boolean;
  takeawayOption?: string;
  totalItem?: number;
}

export default class DiscountService {
  constructor(private fetch: $Fetch) {}

  async fixed(filter?: Filter): Promise<number> {
    return await this.fetch<number>('/discounts/fixed', {
      query: filter
    });
  }

  async voucher(filter?: Filter): Promise<DiscountVoucher> {
    return await this.fetch<DiscountVoucher>('/discounts/voucher', {
      query: filter
    });
  }
}

export type { Filter };

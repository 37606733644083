import type { $Fetch } from 'nitropack';

import type { Profile } from '~/models';

export default class ProfileService {
  constructor(private fetch: $Fetch) {}

  async get(uuid: string): Promise<Profile | null> {
    return await this.fetch<Profile>('/profiles/', {
      query: {
        value: {
          uuid
        }
      }
    });
  }

  async getByUsername(username: string): Promise<Profile | null> {
    return await this.fetch<Profile>(`/profiles/user/${username}`);
  }

  async put(profile: Partial<Profile>) {
    return await this.fetch<Profile>(`/profiles/${profile.uuid}`, {
      method: 'PUT',
      body: profile
    });
  }
}

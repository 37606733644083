import type { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';

import type { CardPayment } from '~/models';

import { useNuxtApp } from '#app';

const useStripeStore = defineStore('customer-stripe', () => {
  const { $http } = useNuxtApp();

  const publishableKey = ref<string>('');

  const cancelIntent = (paymentId: string, reason: string): Promise<void> => {
    return $http.stripe.cancelIntent(paymentId, reason);
  };

  const confirmIntent = (paymentId: string): Promise<PaymentIntent | null> => {
    return $http.stripe.confirmIntent(paymentId);
  };

  const createCustomer = (name: string, email: string): Promise<string> => {
    return $http.stripe.createCustomer(name, email);
  };

  const createIntent = (
    cardPayment: CardPayment
  ): Promise<PaymentIntent | null> => {
    return $http.stripe.createIntent(cardPayment);
  };

  const del = async (paymentId: string): Promise<string> => {
    return (await $http.stripe.delete(paymentId)) ?? '';
  };

  const loadPublishableKey = async (): Promise<void> => {
    publishableKey.value = await $http.stripe.publishableKey();
  };

  const paymentMethods = async (): Promise<PaymentMethod[]> => {
    return (await $http.stripe.paymentMethods()) ?? [];
  };

  return {
    publishableKey,
    cancelIntent,
    confirmIntent,
    createCustomer,
    createIntent,
    delete: del,
    loadPublishableKey,
    paymentMethods
  };
});

export { useStripeStore };

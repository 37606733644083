import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack';
import type { FetchContext, FetchResponse } from 'ofetch';

interface APIErrorDetail {
  type: string;
  message: string;
}

class APIError extends Error {
  code: number;
  details: APIErrorDetail[];

  constructor(code: number, message: string, details: APIErrorDetail[]) {
    super(message);
    this.code = code;
    this.details = details;

    Object.setPrototypeOf(this, APIError.prototype);
  }
}

const camelToSnake = (key: string): string => {
  return key.replace(/[A-Z]/g, function (match) {
    return '_' + match.toLowerCase();
  });
};

const handleQuery = <T extends NitroFetchRequest>(
  query?: NitroFetchOptions<T>['query']
) => {
  if (!query) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const snakeQuery: Record<string, any> = {};

  for (const key in query) {
    const value = query[key];

    if (!value) {
      continue;
    }

    if (Array.isArray(value)) {
      value.forEach((item) => (snakeQuery[camelToSnake(key)] = item));
    } else {
      snakeQuery[camelToSnake(key)] = value;
    }
  }

  return snakeQuery;
};

function getAccessToken() {
  const authStore = useAuthStore();
  return authStore.isAuthenticated
    ? authStore.accessToken
    : authStore.isGuest
    ? authStore.guest?.token.accessToken
    : '';
}

function addOrUpdateHeader(headers, name, value, override = true) {
  if (headers.has(name)) {
    if (override) {
      headers.set(name, value);
    }
  } else {
    headers.append(name, value);
  }
}

function addCommonHeaders(headers: Headers) {
  addOrUpdateHeader(
    headers,
    'Access-Control-Allow-Methods',
    'POST, GET, OPTIONS, PUT, DELETE'
  );
  addOrUpdateHeader(headers, 'Access-Control-Allow-Origin', '*');
  addOrUpdateHeader(headers, 'Content-Type', 'application/json', false);
}

function handleHeaders(headers: Headers) {
  const accessToken = getAccessToken();
  addCommonHeaders(headers);

  if (accessToken) {
    addOrUpdateHeader(headers, 'Authorization', `bearer ${accessToken}`);
  }

  return headers;
}

const onRequestError = () => {
  throw new APIError(504, 'Timeout', []);
};

const onResponseError = (
  context: FetchContext & { response: FetchResponse<ResponseType> }
) => {
  let details: APIErrorDetail[] = [];

  if (Array.isArray(context.response._data.detail)) {
    details = context.response._data.detail.map(({ type, msg, loc: _loc }) => {
      return {
        type: type,
        message: `${msg.charAt(0).toUpperCase() + msg.slice(1)}`
      };
    });
  } else {
    details = [
      {
        type: context.response.type,
        message: context.response._data.detail
      }
    ];
  }

  switch (context.response.status) {
    case 403:
    case 404: {
      break;
    }

    case 412: {
      // Stripe confirmation
      return;
    }

    case 504: {
      eventBus.emit(Events.SERVICE_ERROR, context.response.statusText);
      break;
    }

    default: {
      eventBus.emit(
        Events.SERVICE_ERROR,
        details.map(({ message }) => message).join('\n') ??
          context.response.statusText
      );
    }
  }

  throw new APIError(
    context.response.status,
    context.response.statusText,
    details
  );
};

export {
  handleHeaders,
  handleQuery,
  onRequestError,
  onResponseError,
  APIError
};

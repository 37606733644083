import type { Addon, AddonGroup, ComposeName, Item, Product } from '~/models';

interface UseItem {
  toEqual: (compare: Item) => boolean;
  getTotal: () => number;
  mount: (
    product: Product,
    addonGroups?: AddonGroup[],
    quantity?: number,
    extra?: number,
    drs?: number
  ) => Promise<Item>;
}

const useItem = (item?: Item): UseItem => {
  const _getComposeName = (
    addonGroups: AddonGroup[],
    composeName: ComposeName
  ): string => {
    const getAddonGroupComposeName = (addonGroup: AddonGroup): string => {
      const isValidComposeName = (addon: Addon) => {
        return (
          addon.composeName &&
          addon.composeName === composeName &&
          addon.selected
        );
      };

      return addonGroup.addons
        .filter(isValidComposeName)
        .map((addon) => addon.name)
        .join(' ');
    };

    return addonGroups.map(getAddonGroupComposeName).join(' ');
  };

  const _getName = (product: Product): string => {
    const categoryName = product.category.composeName
      ? product.category.name
      : '';

    if (product.addongroups.length <= 0) {
      return `${categoryName} ${product.name}`.trim();
    }

    const composeNamesFirst = _getComposeName(
      product.addongroups,
      'Show First'
    );
    const composeNamesLast = _getComposeName(product.addongroups, 'Show Last');
    const prependName = `${categoryName} ${composeNamesFirst}`.trim();

    return `${prependName} ${product.name} ${composeNamesLast}`.trim();
  };

  const getTotal = (): number => {
    if (!item) {
      return 0;
    }

    const total = (item.price + item.extra) * item.quantity;
    return Math.round((total + Number.EPSILON) * 100) / 100;
  };

  const mount = async (
    product: Product,
    addonGroups?: AddonGroup[],
    quantity?: number,
    extra?: number,
    drs?: number
  ): Promise<Item> => {
    try {
      useLog().save('useItem.mount', 'begin');
      const cartStore = useCartStore();
      const distountStore = useDiscountStore();

      useLog().save('useItem.mount', 'getDiscount fixed');
      const discount = await distountStore.fixed({
        productUuid: product.uuid,
        totalItem: extra ? product.price + extra : product.price,
        takeawayOption: cartStore.takeaway.type.toUpperCase()
      });

      useLog().save('useItem.mount', 'build item');
      const item: Item = {
        discount,
        extra: extra || 0,
        name: '',
        price: product.price,
        productUuid: product.uuid,
        quantity: quantity || 1,
        addongroups: addonGroups || [],
        drs: drs || product.drsDeposit || 0
      };

      useLog().save('useItem.mount', `getName(${product.name})`);
      item.name = _getName(product);

      useLog().save('useItem.mount', 'end');
      return item;
    } catch (error) {
      useLog().save('useItem.mount', `error: ${error}`);
      throw error;
    }
  };

  const toEqual = (compare: Item): boolean => {
    if (!item) {
      return false;
    }

    if (compare.productUuid !== item.productUuid) {
      return false;
    }

    if (compare.addongroups.length !== item.addongroups.length) {
      return false;
    }

    for (const compareGroup of compare.addongroups) {
      const groupFound = item.addongroups.find(
        (adg) => compareGroup.uuid === adg.uuid
      );

      if (!groupFound) {
        return false;
      }

      if (compareGroup.addons.length !== groupFound.addons.length) {
        return false;
      }

      for (const compareAddon of compareGroup.addons) {
        const addonFound = groupFound.addons.find(
          (addon) => compareAddon.uuid === addon.uuid
        );

        if (!addonFound) {
          return false;
        }

        if (compareAddon.quantity !== addonFound.quantity) {
          return false;
        }
      }
    }

    return true;
  };

  return { toEqual, getTotal, mount };
};

export { useItem };

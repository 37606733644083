import { useReportStore } from '~/stores/report';

const useLog = () => {
  const reportStore = useReportStore();
  const preferenceStore = usePreferenceStore();

  const save = async (id: string, message: string): Promise<void> => {
    try {
      const company = preferenceStore.preference.company.name || 'Foodinn';
      await reportStore.saveLog(`[${company} | ${id}]: ${message}`);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    save
  };
};

export { useLog };

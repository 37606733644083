import moment from 'moment';
import type { $Fetch } from 'nitropack';

import type { Booking } from '~/models';

export default class BookingService {
  constructor(private fetch: $Fetch) {}

  async create(booking: Booking): Promise<Booking> {
    return await this.fetch<Booking>('/booking/', {
      method: 'POST',
      body: {
        ...booking,
        date: moment(booking.date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
    });
  }

  async getAvailableTimes(date: Date): Promise<string[]> {
    const bookingDate = moment(date).format('YYYY-MM-DD');
    return await this.fetch<string[]>('/booking/available-times', {
      query: {
        booking_date: bookingDate
      }
    });
  }
}

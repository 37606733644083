import type {
  GoogleGeometryLocation,
  GoogleLocation
} from '@/models/google.maps';

const useGoogle = () => {
  const googleMapsStore = useGoogleMapsStore();
  const preferenceStore = usePreferenceStore();

  const maps = () => {
    const sw = new google.maps.LatLng(
      Number(preferenceStore.preference.coverArea.swLatitude),
      Number(preferenceStore.preference.coverArea.swLongitude)
    );

    const ne = new google.maps.LatLng(
      Number(preferenceStore.preference.coverArea.neLatitude),
      Number(preferenceStore.preference.coverArea.neLongitude)
    );

    const bounds = new google.maps.LatLngBounds(sw, ne);

    const restaurantLocation: GoogleGeometryLocation = {
      lat: Number(preferenceStore.preference.company.latitude),
      lng: Number(preferenceStore.preference.company.longitude)
    };

    const placeToLocation = (
      place: google.maps.places.PlaceResult
    ): GoogleLocation => {
      const { address_components } = place;

      const composeAddress: Record<string, string> = {
        premise: 'long_name',
        route: 'long_name',
        subpremise: 'long_name',
        administrative_area_level_2: 'long_name',
        neighborhood: 'short_name',
        locality: 'long_name'
      };

      const composeArea: Record<string, string> = {
        administrative_area_level_1: 'long_name',
        sublocality_level_1: 'short_name'
      };

      const address: string[] = [];
      const city: string[] = [];
      let postalCode = '';
      let streetNumber = '';

      if (address_components) {
        for (const address_component of address_components) {
          const addressType = address_component.types[0];
          if (composeAddress[addressType]) {
            const value = address_component[composeAddress[addressType]];

            if (!address.includes(value)) {
              address.push(value);
            }
          } else if (composeArea[addressType]) {
            const value = address_component[composeArea[addressType]];

            if (!city.includes(value)) {
              city.push(value);
            }

            continue;
          }

          switch (addressType) {
            case 'postal_code': {
              postalCode = address_component.short_name;
              break;
            }
            case 'street_number': {
              streetNumber = address_component.short_name;
              break;
            }
          }
        }
      }

      return {
        address: `${streetNumber} ${address.join(', ')}`.trim(),
        city: city.join(', '),
        postalCode
      };
    };

    const getLocationByPostalCode = async (
      postalCode: string
    ): Promise<GoogleLocation | undefined> => {
      return await googleMapsStore.getLocationByPostalCode(
        restaurantLocation,
        bounds,
        preferenceStore.preference.general.countryCode,
        postalCode
      );
    };

    const place = (place: google.maps.places.PlaceResult) => ({
      toLocation: () => placeToLocation(place)
    });

    const postalCode = (postalCode: string) => ({
      toLocation: () => getLocationByPostalCode(postalCode)
    });

    const distanceBetween = async (
      origin: GoogleGeometryLocation,
      destination: GoogleGeometryLocation
    ) => {
      return await googleMapsStore.getDistance(origin, destination);
    };

    return {
      place,
      postalCode,
      distanceBetween
    };
  };

  return {
    maps
  };
};

export { useGoogle };

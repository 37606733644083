import type { $Fetch } from 'nitropack';

import type { AddonGroup } from '~/models';

interface Filter {
  name?: string;
  productUuid?: string;
}

export default class AddonService {
  constructor(private fetch: $Fetch) {}

  async list(filter?: Filter): Promise<AddonGroup[] | null> {
    return await this.fetch<AddonGroup[]>('/addons/', {
      query: {
        displayIn: 'ONLINE',
        ...filter
      }
    });
  }
}

export type { Filter };

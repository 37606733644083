import type { Preference } from '~/models/preference';

type RGBColor = {
  r: number;
  g: number;
  b: number;
  a: number;
};

function getLuminance(color: RGBColor): number {
  return 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b;
}

const getContrastColor = (hex: string): string => {
  const splitColor = hex
    .slice(1)
    .match(/.{2}/g)!
    .map((component) => parseInt(component, 16));

  const rgb: RGBColor = {
    r: splitColor[0],
    g: splitColor[1],
    b: splitColor[2],
    a: splitColor[3] ?? 1
  };

  const luminance = getLuminance(rgb);

  const contrastingColor: RGBColor = {
    r: luminance > 0.5 ? 0 : 255,
    g: luminance > 0.5 ? 0 : 255,
    b: luminance > 0.5 ? 0 : 255,
    a: rgb.a
  };

  return `#${Object.values(contrastingColor)
    .map((component) => component.toString(16).padStart(2, '0'))
    .join('')}`;
};

const mix = (base: string, bg: string, percent: number): string => {
  const baseR = parseInt(base.substring(1, 3), 16);
  const baseG = parseInt(base.substring(3, 5), 16);
  const baseB = parseInt(base.substring(5, 7), 16);

  const bgR = parseInt(bg.substring(1, 3), 16);
  const bgG = parseInt(bg.substring(3, 5), 16);
  const bgB = parseInt(bg.substring(5, 7), 16);

  const deltaR = Math.floor((bgR - baseR) * (percent / 100));
  const deltaG = Math.floor((bgG - baseG) * (percent / 100));
  const deltaB = Math.floor((bgB - baseB) * (percent / 100));

  const r = Math.max(0, Math.min(baseR + deltaR, 255));
  const g = Math.max(0, Math.min(baseG + deltaG, 255));
  const b = Math.max(0, Math.min(baseB + deltaB, 255));

  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  return `#${hexR}${hexG}${hexB}`;
};

const mixColor = (base: string, bg: string): string => {
  const opacity = parseInt(base.substring(7, 9).toUpperCase(), 16);

  if (!opacity) {
    return base;
  }

  const percent = 100 - Math.round((opacity / 255) * 100);

  return mix(base, bg, percent);
};

const defineHead = ({ template }: Preference): void => {
  const {
    $device: { isMobile }
  } = useNuxtApp();

  const bgColor = template.backgroundColor || '#FFFFFF';
  const bgImageUrl = `url(${template.backgroundImageUrl})`;

  const cardBgColor = template.cardBackgroundColor || '#FFFFFF';
  const cardBgColorMix = mixColor(cardBgColor, bgColor);
  const cardTextColor = template.cardTextColor || '#333333';
  const cartTextColorMix = mixColor(cardTextColor, cardBgColorMix);

  const companyColor1 = template.mainColor || '#c60011';
  const companyColor2 = template.secondaryColor || '#dbdb00';
  const companyColor1Mix = mixColor(companyColor1, cardBgColorMix);
  const companyTextColor1 = template.textColor || '#FFFFFF';
  const companyTextColor2 = template.secondaryTextColor || '#333333';

  const headerHeight = `${template.headerSize || 60}px`;
  const headerBgColor = template.headerColor || companyColor1;
  const headerBgColorMix = mixColor(headerBgColor, bgColor);
  const headerTextColor = template.headerTextColor || companyTextColor1;
  const headerTextColorMix = mixColor(headerTextColor, headerBgColorMix);
  const headerTextShadowColor = getContrastColor(headerTextColor);

  const menuHeaderBgImage = `url(${template.menuHeaderImageUrl})`;

  useServerHead({
    htmlAttrs: {
      lang: 'en-GB'
    },
    script: [
      {
        src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9l6QNB1t7NvYBf65jJW7qH7eZmsPq0TI&libraries=places&loading=async',
        type: 'text/javascript'
      }
    ],
    style: [
      {
        innerHTML: `
        :root {
            --bg-color: ${bgColor};
            --bg-color-50: ${bgColor}80;
            --bg-image: ${bgImageUrl};

            --company-color-1: ${companyColor1};
            --company-color-1-50: ${companyColor1Mix}80;
            --company-color-1-10: ${companyColor1Mix}1a;
            --company-color-1-2: ${companyColor1Mix}06;
            --company-color-2: ${companyColor2};

            --company-text-color-1: ${companyTextColor1};
            --company-text-color-2: ${companyTextColor2};
            
            --card-text-color: ${cardTextColor};
            --card-text-color-50: ${cartTextColorMix}80;
            --card-text-color-25: ${cartTextColorMix}40;
            --card-text-color-15: ${cartTextColorMix}26;
            --card-text-color-10: ${cartTextColorMix}1a;
            --card-bg-color: ${cardBgColor};
            --card-bg-color-50: ${cardBgColorMix}80;

            --header-height: ${headerHeight};
            --header-bg-color: ${headerBgColor};
            --header-bg-light-contrast: ${headerTextColorMix}20;
            --header-text-color: ${headerTextColor};
            --header-text-shadow-color: ${headerTextShadowColor};

            --footer-bg-color: #121212f2;
            --footer-text-color: #ffffff;

            --menu-header-bg-image: ${menuHeaderBgImage};
          }
        `
      }
    ],
    bodyAttrs: {
      class: isMobile ? 'mobile' : 'desktop'
    }
  });
};

export { defineHead };

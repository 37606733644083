import type { $Fetch } from 'nitropack';

import type { Cart } from '~/models';

export default class CartService {
  constructor(private fetch: $Fetch) {}

  async get(uuid: string): Promise<Cart | null> {
    return await this.fetch<Cart>(`/carts/${uuid}`);
  }

  async post(cart: Partial<Cart>): Promise<Cart> {
    return await this.fetch<Cart>('/carts/', {
      method: 'POST',
      body: cart
    });
  }

  async put(cart: Partial<Cart>): Promise<Cart> {
    return await this.fetch<Cart>(`/carts/${cart.uuid}`, {
      method: 'PUT',
      body: cart
    });
  }

  async delete(uuid: string): Promise<void> {
    await this.fetch<Cart>(`/carts/${uuid}`, {
      method: 'DELETE'
    });
  }
}

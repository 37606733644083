import type {
  CountryCode,
  GoogleDistance,
  GoogleGeometryLocation,
  GoogleLocation
} from '~/models';

import { useNuxtApp } from '#app';

const useGoogleMapsStore = defineStore('customer-google-maps', () => {
  const { $http } = useNuxtApp();

  const getDistance = async (
    origin: GoogleGeometryLocation,
    destination: GoogleGeometryLocation
  ): Promise<GoogleDistance> => {
    return $http.google.maps.getDistance(origin, destination);
  };

  const getLocationByPostalCode = (
    origin: GoogleGeometryLocation,
    bounds: google.maps.LatLngBounds,
    country: CountryCode = 'IE',
    postalCode: string
  ): Promise<GoogleLocation | undefined> => {
    return $http.google.maps.getLocationByPostalCode(
      origin,
      bounds,
      country,
      postalCode
    );
  };

  return {
    getDistance,
    getLocationByPostalCode
  };
});

export { useGoogleMapsStore };

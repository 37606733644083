import type { $Fetch } from 'nitropack';

import type { Company } from '~/models';

interface Filter {
  name?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  desc?: boolean;
}

export default class CompanyService {
  constructor(private fetch: $Fetch) {}

  async list(filter?: Filter): Promise<Company[] | null> {
    return await this.fetch<Company[]>('/companies/', {
      query: filter
    });
  }
}

const useBaseURL = (): string => {
  if (!import.meta.env.SSR) {
    return import.meta.env.VITE_BASE_API;
  }

  if (!process.env.VITE_BASE_API) {
    throw new Error('Base API is missing');
  }

  return process.env.VITE_BASE_API;
};

export { useBaseURL };

import type { $Fetch } from 'nitropack';

import type { Preference } from '~/models';

export default class PreferenceSerice {
  constructor(private fetch: $Fetch) {}

  async get(): Promise<Preference | null> {
    return await this.fetch<Preference>('/preferences/');
  }

  async getServiceCharge(): Promise<number> {
    return await this.fetch<number>('/preferences/servicefee/');
  }
}

import moment from 'moment';

import type { DayTime, WeekTimes } from '~/models';

interface UseSchedule {
  inService: () => boolean;
  todayTime: () => string;
}

const useSchedule = (times: WeekTimes): UseSchedule => {
  const WEEK_DAYS = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];

  const getNextOpenDay = (currentDayNo: number): string | undefined => {
    const reorderWithCurrentDayAsLast = (currentDay: number): string[] => {
      const nextDay = currentDay === 6 ? 0 : currentDay + 1;
      return [...WEEK_DAYS.slice(nextDay), ...WEEK_DAYS.slice(0, nextDay)];
    };

    const nextWeekDays = reorderWithCurrentDayAsLast(currentDayNo);

    const firstValidDay = (x: string) => {
      const dayTime: DayTime = times[x];
      return dayTime.start !== dayTime.end;
    };

    const nextOpenDayName = nextWeekDays.find(firstValidDay);

    return nextOpenDayName;
  };

  const inService = (): boolean => {
    const now = new Date();
    let start = new Date();
    let end = new Date();

    const mountDate = (weekday: string) => {
      const dayTime: DayTime = times[weekday];
      const hourStart = dayTime.start.substring(0, 2);
      const minuteStart = dayTime.start.substring(3, 5);
      const hourEnd = dayTime.end.substring(0, 2);
      const minuteEnd = dayTime.end.substring(3, 5);

      start.setHours(Number(hourStart), Number(minuteStart), 0);
      end.setHours(Number(hourEnd), Number(minuteEnd), 0);

      if (start.getTime() > end.getTime()) {
        end.setDate(end.getDate() + 1);
      }
    };

    let weekday = WEEK_DAYS[now.getDay()];
    const dayTime: DayTime = times[weekday];
    mountDate(weekday);

    const outOfServiceToday = dayTime.start === dayTime.end;

    if (outOfServiceToday || now.valueOf() < start.valueOf()) {
      weekday = WEEK_DAYS[now.getDay() - 1 >= 0 ? now.getDay() - 1 : 6];
      start = moment().add(-1, 'days').toDate();
      end = moment().add(-1, 'days').toDate();
      mountDate(weekday);
    }

    return now.valueOf() >= start.valueOf() && now.valueOf() <= end.valueOf();
  };

  const todayTime = (): string => {
    const currentDayNo = new Date().getDay();
    const weekDayName = WEEK_DAYS[currentDayNo];

    const workSchedule: DayTime = times[weekDayName];

    if (workSchedule.start !== workSchedule.end) {
      const openingTime = moment(workSchedule.start, 'HH:mm');

      if (openingTime.isAfter(moment())) {
        return workSchedule.start;
      }
    }

    const nextOpenDayName = getNextOpenDay(currentDayNo);

    if (!nextOpenDayName) {
      return '';
    }

    const nextDayTime: DayTime = times[nextOpenDayName];
    return `${nextDayTime.start} ${nextOpenDayName}`;
  };

  return {
    inService,
    todayTime
  };
};

export { useSchedule };

const useNotify = () => {
  const toast = useToast();

  const error = (message: string): void => {
    toast.add({
      title: message,
      color: 'rose'
    });
  };

  const success = (message: string): void => {
    toast.add({
      title: message,
      color: 'green'
    });
  };

  return {
    error,
    success
  };
};

export { useNotify };

import type { Auth, Guest, Profile, User } from '~/models';

import { useNuxtApp } from '#app';

const MASTER_PROFILE: Profile = {
  fullName: 'Master',
  stripeId: '',
  paymentMethods: [],
  isNewUser: false,
  uuid: '',
  user: {
    username: 'master',
    email: 'master@foodinn.ie',
    isDisabled: false
  },
  userId: 1
} as const;

interface AuthState {
  accessToken: string;
  username: string;
  remember: string;
}

const useAuthStore = defineStore(
  'customer-auth',
  () => {
    const { $http } = useNuxtApp();

    const defaultState = (): AuthState => ({
      accessToken: '',
      username: '',
      remember: ''
    });

    const state = ref<AuthState>(defaultState());
    const guest = ref<Guest | null>(null);
    const profile = ref<Profile | null>(null);

    const handleLogin = async (
      username: string,
      auth: Auth | null
    ): Promise<void> => {
      if (!auth) {
        return;
      }

      profile.value =
        username === 'master'
          ? MASTER_PROFILE
          : await $http.profile.getByUsername(username);

      if (!profile.value) {
        return;
      }

      profile.value.isNewUser =
        ((await $http.order.count(['Completed'])) ?? 0) === 0;

      state.value.accessToken = auth.accessToken;
      state.value.username = profile.value.user.username;

      guestLogout();
    };

    const changePassword = (
      currentPass: string,
      newPass: string
    ): Promise<void> => {
      return $http.auth.changePassword(currentPass, newPass);
    };

    const confirmEmail = async (email: string, code: string): Promise<void> => {
      const auth = await $http.auth.confirmEmail(email, code);
      await handleLogin(email, auth);
    };

    const forgotPassword = (email: string): Promise<void> => {
      return $http.auth.forgotPassword(email);
    };

    const forgotPasswordGet = async (uuid: string): Promise<string> => {
      return (await $http.auth.forgotPasswordGet(uuid)) ?? '';
    };

    const guestLogin = async (email: string): Promise<void> => {
      guest.value = await $http.auth.guestLogin({
        email: email,
        name: email
      });

      if (guest.value) {
        guest.value.profile.isNewUser =
          ((await $http.order.count(['Completed'])) ?? 0) === 0;
      }
    };

    const guestLogout = (): void => {
      guest.value = null;
    };

    const guestUserName = computed<string>(() => {
      if (!guest.value) {
        return '';
      }

      const atPosition = guest.value.profile.fullName.indexOf('@');

      return atPosition > 0
        ? guest.value.profile.fullName.slice(0, atPosition)
        : guest.value.profile.fullName;
    });

    const login = async (username: string, password: string): Promise<void> => {
      const auth = await $http.auth.login(username, password);
      await handleLogin(username, auth);
    };

    const logout = (): void => {
      state.value.accessToken = '';
      state.value.username = '';
      profile.value = null;
    };

    const refreshProfile = async (): Promise<void> => {
      if (guest.value) {
        guestLogin(guest.value.profile.user.username);
        return;
      }

      if (!state.value.accessToken || !state.value.username) {
        logout();
        return;
      }

      profile.value = await $http.profile.getByUsername(state.value.username);

      if (profile.value) {
        const orderCount = await $http.order.count(['Completed']);
        profile.value.isNewUser = (orderCount || 0) === 0;
      }
    };

    const register = (user: User): Promise<User | null> => {
      return $http.auth.register(user);
    };

    const resendConfirmEmail = async (email: string): Promise<void> => {
      await $http.auth.resendConfirmEmail(email);
    };

    const resetPassword = (email: string, newPass: string): Promise<void> => {
      return $http.auth.resetPassword(email, newPass);
    };

    const setRemember = (payload: string): void => {
      state.value.remember = payload;
    };

    return {
      state,
      accessToken: computed<string>(() => state.value.accessToken),
      remember: computed<string>(() => state.value.remember),
      profile,
      guest,
      guestUserName,
      changePassword,
      confirmEmail,
      forgotPassword,
      forgotPasswordGet,
      guestLogin,
      guestLogout,
      login,
      logout,
      refreshProfile,
      register,
      resendConfirmEmail,
      resetPassword,
      setRemember,
      isAuthenticated: computed<boolean>(() => !!state.value.accessToken),
      isGuest: computed<boolean>(() => !!guest.value)
    };
  },
  {
    persist: [
      {
        pick: ['state'],
        storage: persistedState.localStorage
      },
      {
        pick: ['state'],
        storage: persistedState.cookiesWithOptions({
          path: '/',
          sameSite: 'strict',
          maxAge: 60 * 60 * 24 * 7,
          watch: true
        })
      }
    ]
  }
);

export { useAuthStore };
